import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { Icomoon, Image, Textarea, VideoPreview } from '@src/components/atoms';
import { BackNavigatorV2, Dialog, DragAndDrop, ThemeButton } from '@src/components/molecules';
import {
  useCreateMarketplaceDraftPostMutation,
  useMarketplaceDraftPostsForInfluencerQuery,
  useMarketplaceForInfluencerQuery,
} from '@src/graphql/hooks';
import { usePageLayout, useQueryHelper } from '@src/libs/hooks';
import { isVideoThumbnail } from '@src/libs/image';
import { mainBlack } from '@src/libs/palette';
import { THEME } from '@src/libs/theme';
import { ViewportType } from '@src/libs/types';
import { lineWidgetState, useRecoil } from '@src/recoilAtoms';
import { generatePath, ROUTES } from '@src/shared/routes';

interface FormValues {
  caption: string;
  materialsUrl: string[];
}

const SubmitDraftPost = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { setRecoilState } = useRecoil(lineWidgetState);
  const { isMobileView } = usePageLayout();
  const { enqueueSnackbar, navigate, params, t } = useQueryHelper();
  const campaignId = Number(params.id);

  const {
    formState: { isSubmitting },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<FormValues>({
    defaultValues: { caption: '', materialsUrl: [] },
  });
  const [caption, materialsUrl] = watch(['caption', 'materialsUrl']);
  const isMultiplePosts = materialsUrl.length > 1;

  const { data } = useMarketplaceForInfluencerQuery({ variables: { pk: campaignId } });
  const { data: dataDraftPosts } = useMarketplaceDraftPostsForInfluencerQuery({
    variables: { marketplaceId: campaignId },
  });
  const [createDraftPost] = useCreateMarketplaceDraftPostMutation();
  const marketplaceForInfluencer = data?.marketplaceForInfluencer;
  const draftPost = dataDraftPosts?.marketplaceDraftPostsForInfluencer || [];
  const latestDraftPosts = draftPost?.[draftPost.length - 1];
  const disabledSubmitButton = isUploading || (!caption && !materialsUrl.length);

  useEffect(() => {
    setRecoilState({ extendedBottom: isMobileView ? 72 : 0 });

    return () => {
      setRecoilState({ extendedBottom: 0 });
    };
  }, [isMobileView]);

  const onClickConfirmDialog = () => {
    trigger().then(isValid => {
      if (isValid) {
        setIsDialogOpen(true);
      }
    });
  };

  const onSubmit = async (values: FormValues) => {
    try {
      await createDraftPost({
        variables: {
          input: { campaignId, ...values },
        },
      });
      setIsDialogOpen(false);
      // redirect after dialog closed 0.1s
      enqueueSnackbar(t('succeededInUpdating'), { variant: 'success' });
      setTimeout(() => {
        navigate(generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID, { id: campaignId }));
      }, 100);
    } catch (err) {
      enqueueSnackbar(t(err), { variant: 'error' });
    }
  };

  const actionButtons = (
    <ThemeButton
      css={styles.submitBtn}
      customPalette={mainBlack}
      disabled={disabledSubmitButton}
      text="Submit the draft post"
      onClick={onClickConfirmDialog}
    />
  );

  return (
    <div css={!isMobileView && { padding: 24 }}>
      <Dialog
        cancelButtonProps={{
          text: 'Close',
          onClick: () => setIsDialogOpen(false),
        }}
        contentStyles={{
          maxWidth: 648,
          width: '90%',
        }}
        nextButtonProps={{
          disabled: isSubmitting || disabledSubmitButton,
          text: 'Submit',
          onClick: () => formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true })),
        }}
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <div css={styles.dialogContent}>
          <div css={styles.dialogTitle}>{t('Title.Are you sure to submit draft post')}</div>
          <div css={styles.dialogDescription}>{t('Once you submitted, you cannot change the draft post contents')}</div>
          <div css={styles.dialogInfo}>
            {!!materialsUrl.length && (
              <div
                css={{
                  alignItems: 'center',
                  display: 'grid',
                  maxWidth: isMobileView ? 'fill-available' : 200,
                  justifyContent: 'center',
                }}
              >
                <div css={styles.dialogImagesContainer}>
                  {materialsUrl.map((url, index) =>
                    isVideoThumbnail(url) ? (
                      <VideoPreview
                        css={{ borderRadius: 4 }}
                        height={isMultiplePosts ? (isMobileView ? 140 : 96) : 200}
                        key={index}
                        width={isMultiplePosts ? (isMobileView ? 140 : 96) : 200}
                        url={url}
                      />
                    ) : (
                      <Image
                        css={{ borderRadius: 4 }}
                        height={isMultiplePosts ? (isMobileView ? 140 : 96) : 200}
                        key={index}
                        width={isMultiplePosts ? (isMobileView ? 140 : 96) : 200}
                        src={url}
                      />
                    )
                  )}
                </div>
              </div>
            )}
            {caption && <div css={styles.dialogInfoCaption}>{caption}</div>}
          </div>
        </div>
      </Dialog>

      <form css={styles.form} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {!isMobileView && (
          <BackNavigatorV2
            actionItem={actionButtons}
            backPath={generatePath(ROUTES.MY_JOBS_MARKETPLACE_ID, { id: campaignId })}
            title="Submit draft"
          />
        )}

        <div css={styles.inputContainer}>
          <div css={styles.uploadImageContainer}>
            {materialsUrl.map((url, index) => (
              <div css={styles.uploadedImage} key={index}>
                {isVideoThumbnail(url) ? (
                  <VideoPreview height={112} url={url} width={112} />
                ) : (
                  <Image height={112} src={url} width={112} />
                )}
                <div
                  onClick={() =>
                    setValue('materialsUrl', [...materialsUrl.slice(0, index), ...materialsUrl.slice(index + 1)])
                  }
                >
                  <Icomoon color="#6e7c89" icon="clear" size={6} />
                </div>
              </div>
            ))}
            <DragAndDrop
              accept="image/gif, image/jpeg, image/png, video/avi, video/mp4, video/quicktime"
              customContent={<div css={styles.uploadBtn}>{<Icomoon color="#dee5ec" icon="plus" />}</div>}
              multiple
              value={materialsUrl}
              onChange={val => setValue('materialsUrl', val)}
              onProgress={setIsUploading}
            />
          </div>

          <Textarea
            css={styles.textarea}
            placeholder={t('Please input the draft caption here')}
            {...register('caption')}
          />

          <div css={styles.hashtagsContainer}>
            <div>{t('Please include hashtags required for this campaign')}</div>
            <div>
              {marketplaceForInfluencer?.hashtags.map((hashtag, index) => (
                <div key={index}>{`#${hashtag}`}</div>
              ))}
            </div>
          </div>

          {latestDraftPosts?.rejectedMessage && (
            <div css={styles.warningMessage}>
              <div>{t('Modify Feedback')}</div>
              <div>{t(`Annotation.Your draft post wasn't approved, please check below`)}</div>
              <div>{latestDraftPosts.rejectedMessage}</div>
            </div>
          )}
        </div>
      </form>

      {isMobileView && <div css={styles.mobileActionContainer}>{actionButtons}</div>}
    </div>
  );
};

const styles = {
  dialogContent: css({
    padding: '24px 8px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      padding: '48px 8px',
    },
  }),
  dialogDescription: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.normal,
    marginBottom: 16,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderBottom: '1px solid #eef3f7',
      marginBottom: 24,
      paddingBottom: 16,
    },
  }),
  dialogImagesContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
    minHeight: 200,
    minWidth: 200,
  }),
  dialogInfo: css({
    display: 'grid',
    gap: THEME.box.gaps.l,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      display: 'flex',
      gap: THEME.box.gaps.xl,
    },
  }),
  dialogInfoCaption: css({
    background: '#f6f8fa',
    borderRadius: 4,
    fontSize: THEME.font.sizes.normal,
    padding: 8,
    whiteSpace: 'pre-line',
    width: 'fill-available',
    wordBreak: 'break-word',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      minHeight: 184,
    },
  }),
  dialogTitle: css({
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subTitle,
    fontWeight: 600,
    marginBottom: 12,

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      marginBottom: 16,
    },
  }),
  warningMessage: css({
    background: '#f6f8fa',
    borderRadius: 15,
    color: THEME.font.colors.black.main,
    fontSize: THEME.font.sizes.subordinate,
    padding: 16,

    '& > div:nth-of-type(1)': {
      fontWeight: 600,
    },

    '& > div:nth-of-type(2)': {
      marginBottom: 10,
    },

    '& > div:nth-of-type(3)': {
      whiteSpace: 'pre-line',
      wordBreak: 'break-word',
    },
  }),
  form: css({
    overflow: 'hidden',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      borderRadius: 23,
    },
  }),
  hashtagsContainer: css({
    marginBottom: 24,

    '& > div:nth-of-type(1)': {
      color: THEME.font.colors.black.main,
      fontSize: THEME.font.sizes.normal,
      marginBottom: 12,
    },

    '& > div:nth-of-type(2)': {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      gap: THEME.box.gaps.s,

      '& > div': {
        background: '#f6f8Fa',
        borderRadius: 12,
        color: THEME.font.colors.gray.main,
        fontSize: THEME.font.sizes.normal,
        padding: '4px 24px',
      },
    },
  }),
  inputContainer: css({
    '--paddingVertical': '32px',
    '--toolbar': '40px',

    background: THEME.colors.white,
    height: 'calc(100vh - var(--paddingVertical))',
    padding: '16px 20px',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 'unset',
      padding: '24px 32px',
    },
  }),
  mobileActionContainer: css({
    bottom: 0,
    boxShadow: THEME.box.shadows.outer,
    padding: '16px 12px 24px 16px',
    position: 'fixed',
    width: 'fill-available',
  }),
  submitBtn: css({
    background: THEME.colors.black.main,
    borderRadius: 9,
    height: 56,
    width: 'fill-available',

    [`@media (min-width: ${ViewportType.TABLET}px)`]: {
      height: 40,
      width: 226,
    },
  }),
  textarea: css({
    border: 'none',
    boxShadow: 'none',
    fontSize: THEME.font.sizes.normal,
    marginBottom: 24,
  }),
  uploadBtn: css({
    alignItems: 'center',
    background: '#f6f8fa',
    borderRadius: 4,
    display: 'flex',
    height: 112,
    justifyContent: 'center',
    width: 112,
  }),
  uploadedImage: css({
    height: 112,
    borderRadius: 4,
    overflow: 'hidden',
    position: 'relative',
    width: 112,

    '& > div:last-child': {
      alignItems: 'center',
      background: THEME.colors.white,
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'flex',
      height: 12,
      justifyContent: 'center',
      position: 'absolute',
      right: 8,
      top: 8,
      width: 12,

      "[dir = 'rtl'] & ": {
        left: 8,
        right: 'unset',
      },
    },
  }),
  uploadImageContainer: css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: THEME.box.gaps.s,
    marginBottom: 24,
  }),
};

export default SubmitDraftPost;
